import keepspaceApi from 'services/keepspaceApi';

export const FETCH_SUPPLIERS_BEGIN = 'FETCH_SUPPLIERS_BEGIN';
export const FETCH_SUPPLIERS_SUCCESS = 'FETCH_SUPPLIERS_SUCCESS';
export const FETCH_SUPPLIERS_FAILURE = 'FETCH_SUPPLIERS_FAILURE'

export function fetchSuppliers() {
    return dispatch => {
        dispatch(fetchSuppliersBegin());
        return keepspaceApi.getSuppliers()
            .then(json => {
                dispatch(fetchSuppliersSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchSuppliersFailure(error))
            });
    };
}


export const fetchSuppliersBegin = () => ({
    type: FETCH_SUPPLIERS_BEGIN
});

export const fetchSuppliersSuccess = json => ({
    type: FETCH_SUPPLIERS_SUCCESS,
    data: json
});

export const fetchSuppliersFailure = error => ({
    type: FETCH_SUPPLIERS_FAILURE,
    payload: { error }
});

import {
    FETCH_CUSTOMERS_BEGIN,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
} from 'actions/customerActions';

import { applyCustomerInterface } from 'Interfaces';

const initialState = {
    customers: [],
    loading: false,
    error: null,
    uploading: false,
}

export default function customersReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_CUSTOMERS_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CUSTOMERS_SUCCESS:
            return  {
                ...state,
                loading: false,
                customers: action.payload.customers.map(customer => ({
                    ...applyCustomerInterface(customer)
                }))
            };
        case FETCH_CUSTOMERS_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                customers: []
            };



        default:
            return state
    }
}

import keepspaceApi from 'services/keepspaceApi';

export const FETCH_CUSTOMERS_BEGIN = 'FETCH_CUSTOMERS_BEGIN';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE'

export const fetchCustomersBegin = () => ({
    type: FETCH_CUSTOMERS_BEGIN
});

export const fetchCustomersSuccess = customers => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: { customers }
});

export const fetchCustomersFailure = error => ({
    type: FETCH_CUSTOMERS_FAILURE,
    payload: { error }
});


export function fetchCustomers() {
    return dispatch => {
        dispatch(fetchCustomersBegin());
        return keepspaceApi.getCustomers()
            .then(json => dispatch(fetchCustomersSuccess(json)))
            .catch(error => dispatch(fetchCustomersFailure(error)))
    };
}


import {
    FETCH_BUNDLES_BEGIN,
    FETCH_BUNDLES_SUCCESS,
    FETCH_BUNDLES_FAILURE,
    SET_SEARCH_TERM
} from 'actions/bundleActions';

const initialState = {
    bundles: [],
    loading: false,
    creating: false,
    fetchingComponents: false,
    error: null,
    searchTerm: '',
    searchActive: false,
    newBundleErrors: null,
    components: [],
}

export default function bundlesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BUNDLES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                bundles: [],
            };

        case FETCH_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                bundles: action.payload.bundles,
            };

        case FETCH_BUNDLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                bundles: []
            };

        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.term
            }

        default:
            return state
    }
}

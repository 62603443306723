import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reverseUserRoute } from 'routes';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BundleTable from 'components/Table/BundleTable';
import Search from "components/Search/Search";
import Header from 'components/Header/Header';
import AddNew from 'components/Help/AddNew';

import { setSearchTerm } from 'actions/bundleActions';
import {UID} from 'AppConstants';
import getV4RedirectionLink from '../../../utils/magicLoginV4';

const BundlesIndex = (props) => {
    const dispatch = useDispatch();
    const { bundles, permittedToAccess } = props
    const searchTerm = useSelector((state) => state.bundlesReducer.searchTerm);

    const classes = useStyles();

    const url = (row) => `${reverseUserRoute('Bundles')}/${row.id}`

    const handleInputChange = (event) => {
      const text = event.target.value;
      dispatch(setSearchTerm(text));
    };

    const applyTermSearch = () => {
      let searchedBundles = bundles
      if (searchTerm.length > 0) {
        searchedBundles = bundles.filter(bundle => (
          search(searchTerm, bundle.sku) ||
          (searchTerm === bundle.id.toString()) ||
          search(searchTerm, bundle.title)
        ))
      }
      return searchedBundles;
    }

    const columns = [
      { label: 'SKU', tooltip: '', value: 'sku' },
      { label: 'Title', tooltip: '', value: 'title' },
      { label: 'Bundles Available', tooltip: '', value: 'quantity_available' },
    ]

    return (
        <div className={classes.root}>
              <Header title="space" subtitle="Bundles"/>
              <div className={classes.menuContainer}>
                <Search
                  searchTerm={searchTerm}
                  onChange={handleInputChange}
                />
                <Button
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  aria-label="Add New Bundle"
                  onClick={()=>{window.open(getV4RedirectionLink(`/space/bundles/new`), '_blank')}}
                  disabled={!permittedToAccess("bundles:create")}
                  >
                  Add Bundle
                </Button>
              </div>
              { bundles.length === 0 ? (
                  <AddNew title="bundle" />
              ):(
                  <BundleTable bundles={applyTermSearch()} columns={columns} url={url}/>
              )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
  button: {
    minWidth: "150px",
    margin: 5,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

function search(searchTerm, stringToProcess) {
  let stp = stringToProcess.toLowerCase()
  let st = searchTerm.toLowerCase()
  return stp.includes(st)
}

export default BundlesIndex;

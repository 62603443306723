import {
    FETCH_SUPPLIERS_BEGIN,
    FETCH_SUPPLIERS_SUCCESS,
    FETCH_SUPPLIERS_FAILURE,
} from 'actions/supplierActions';


const initialState = {
    suppliers: [],
    loading: false,
    error: null,
    uploading: false,
}

export default function suppliersReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_SUPPLIERS_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_SUPPLIERS_SUCCESS:
            return  {
                ...state,
                loading: false,
                suppliers: action.data
            };
        case FETCH_SUPPLIERS_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                suppliers: []
            };

        default:
            return state
    }
}

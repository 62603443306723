import keepspaceApi from 'services/keepspaceApi';

export const FETCH_BUNDLES_BEGIN = 'FETCH_BUNDLES_BEGIN';
export const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS';
export const FETCH_BUNDLES_FAILURE = 'FETCH_BUNDLES_FAILURE'

export const FETCH_BUNDLE_COMPONENTS_BEGIN = 'FETCH_BUNDLE_COMPONENTS_BEGIN'
export const FETCH_BUNDLE_COMPONENTS_SUCCESS = 'FETCH_BUNDLE_COMPONENTS_SUCCESS'
export const FETCH_BUNDLE_COMPONENTS_FAILURE = 'FETCH_BUNDLE_COMPONENTS_FAILURE'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'

export function fetchBundles() {
    return dispatch => {
        dispatch(fetchBundlesBegin());
        return keepspaceApi.getBundles()
            .then(json => {
                dispatch(fetchBundlesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchBundlesFailure(error))
            });
    };
}


export const fetchBundlesBegin = () => ({
    type: FETCH_BUNDLES_BEGIN
});

export const fetchBundlesSuccess = bundles => ({
    type: FETCH_BUNDLES_SUCCESS,
    payload: { bundles }
});

export const fetchBundlesFailure = error => ({
    type: FETCH_BUNDLES_FAILURE,
    payload: { error }
});


export function setSearchTerm(term) {
    return { type: SET_SEARCH_TERM, term };
};

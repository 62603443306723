import { apiPath, authHeaders } from 'AppConstants';
import keepspaceApi from 'services/keepspaceApi';
import { apiErrorHandler } from 'utils/apiErrorHandler';

export const FETCH_ITEMS_BEGIN = 'FETCH_ITEMS_BEGIN';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE'

export const SEARCH_ITEMS_BEGIN = 'SEARCH_ITEMS_BEGIN'
export const SEARCH_ITEMS_SUCCESS = 'SEARCH_ITEMS_SUCCESS'
export const SEARCH_ITEMS_FAILURE = 'SEARCH_ITEMS_FAILURE'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_SEARCH_ACTIVE = 'SET_SEARCH_ACTIVE'

export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER'
export const UPDATE_PER_PAGE_COUNT = 'UPDATE_PER_PAGE_COUNT'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'

export const UPDATE_ARCHIVED_FILTER = 'UPDATE_ARCHIVED_FILTER';
export const UPDATE_AVAILABLE_FILTER = 'UPDATE_AVAILABLE_FILTER';
export const UPDATE_LOW_STOCK_FILTER = 'UPDATE_LOW_STOCK_FILTER';
export const UPDATE_CATEGORY_SELECTION ='UPDATE_CATEGORY_SELECTION';


// Get Items for Table


export function fetchItems(args) {
    return dispatch => {
        dispatch(fetchItemsBegin());
        return keepspaceApi.getItems(args)
            .then(json => {
                dispatch(fetchItemsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchItemsFailure(error))
            });
    };
}

export const updatePageNumber = (pageNo) => ({
    type: UPDATE_PAGE_NUMBER,
    payload: { pageNo }
});

export const updatePerPageCount = (count) => ({
    type: UPDATE_PER_PAGE_COUNT,
    payload: { count }
});


export const fetchItemsBegin = () => ({
    type: FETCH_ITEMS_BEGIN
});

export const fetchItemsSuccess = items => ({
    type: FETCH_ITEMS_SUCCESS,
    payload: {
        products:items.data.products,
        perPage:items.data.pagination.per_page,
        page:items.data.pagination.current_page,
        totalCount:items.data.pagination.total_count,
        totalPages:items.data.pagination.total_pages,
        categories:items.data.filters.categories,
        pagination: items.data.pagination
     }
});

export const fetchItemsFailure = error => ({
    type: FETCH_ITEMS_FAILURE,
    payload: { error }
});


// Filtering & Searching the Table


export function setSearchTerm(term) {
    return { type: SET_SEARCH_TERM, term };
}

export function setSearchActive(active) {
    return { type: SET_SEARCH_ACTIVE, active };
}

export const clearProductsAction = () => async (dispatch) => {
    await Promise.all([
        dispatch(setSearchTerm("")), // <-- async dispatch chaining in action
        dispatch(clearProducts())
      ]);
};

export const clearProducts = () => ({
    type: CLEAR_PRODUCTS
});


export const updateArchivedFilter = (property, value) => ({
    type: UPDATE_ARCHIVED_FILTER,
    property,
    value
});

export const updateAvailableFilter = (property, value, isChecked) => ({
    type: UPDATE_AVAILABLE_FILTER,
    property,
    value,
    isChecked
});


export const updateLowStockFilter = (isChecked) => ({
    type: UPDATE_LOW_STOCK_FILTER,
    isChecked
});

export const updateCategoryFilter = (value,isChecked)=> ({
    type: UPDATE_CATEGORY_SELECTION,
    value,
    isChecked
});


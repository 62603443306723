import React, {Component} from 'react';
import {Route} from 'react-router-dom';
import {connect} from 'react-redux';

import {LinearProgress, withStyles} from '@material-ui/core';

import {fetchBundles} from 'actions/bundleActions';
import {fetchItems, clearProducts} from 'actions/spaceActions';
import Loader from 'layouts/AdminLayout/components/Loader';
import getV4RedirectionLink from '../../../utils/magicLoginV4';
import RedirectToV4 from '../RedirectToV4';
import BundlesIndex from './BundlesIndex';
import checkPermission from "components/checkPermission";

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: 'auto'
  },
  progress: {
    borderRadius: "0px"
  },
});

class Bundles extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if(!this.props.items_loading) {
      this.props.dispatch(fetchItems());
    }

    if(!this.props.loading) {
      this.props.dispatch(fetchBundles());
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearProducts());
  }
  LinearIndeterminate() {
    return (
      <div>
        <LinearProgress />
        <br />
        <LinearProgress color='secondary' />
      </div>
    );
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page)
  }

  render() {
    const {classes} = this.props;

    return (

      <div>
        {
          this.props.loading ? (
            <Loader />
          ) : null
        }
        <div className={classes.root}>

          {!this.props.loading &&


            <>
              <Route path='/user/:uid/space/bundles/' exact render={(routeProps) => <BundlesIndex {...routeProps} bundles={this.props.bundles} permittedToAccess={this.permittedToAccess} />} />
              <Route path='/user/:uid/space/bundles/:id(\d+)/' exact render={() =>     <RedirectToV4 to={getV4RedirectionLink(`/space/bundles/`)}/>} />
              <Route path='/user/:uid/space/bundles/:id(\d+)/:tab' exact render={() => <RedirectToV4 to={getV4RedirectionLink('/space/bundles/')}/>} />

              <Route path='/user/:uid/space/bundles/add' exact render={() => <RedirectToV4 to={getV4RedirectionLink('/space/bundles/new')}/>} />

            </>
          }


        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  bundles: state.bundlesReducer.bundles,
  items: state.spaceReducer.items,
  items_loading: state.spaceReducer.loading,
  loading: state.bundlesReducer.loading,
  creating: state.bundlesReducer.creating,
  error: state.bundlesReducer.error,
  newBundleErrors: state.bundlesReducer.newBundleErrors,
  currentOrganisation: state.organisationsReducer.currentOrganisation
})

export default connect(mapStateToProps)(withStyles(styles)(Bundles));

import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useLocation } from 'react-router-dom';
import getV4RedirectionLink from '../../utils/magicLoginV4';

const useStyles = makeStyles((theme) => ({


  link: {
    color: '#3d7bf6',
  },
  depricationAlert: {
    fontSize: '16px',
  },

}));


const DeprecationAlert = () => {
  const classes = useStyles();
  const location = useLocation(); // Get the location object

  /**
   * @description - Method to decide the v4Link to be redirected on clicking "Platform V4.2.3" in deprecation alert text
   * @returns {string}
   */
  const getV4Link = () => {
    let v4Path = undefined;

    const splittedPathname = location?.pathname?.split('/');
    const extractedPath = splittedPathname.slice(3);

    if (extractedPath?.[0] === "terminologies"){
      v4Path = `/space/bundles`;
    }

    if(extractedPath?.[0] === 'chat'){
      v4Path = '/support/tickets?tab=open'
    }

    if (['space', 'manage'].includes(extractedPath[0])) {
      v4Path = `/${extractedPath.join('/')}`;
    }

    return getV4RedirectionLink(v4Path);
  };

  return (
    <Alert className={classes.depricationAlert} severity='warning'>
      We’d like to inform you that this platform will soon be deprecated. <br/>
      To continue enjoying ongoing updates and enhanced features, we encourage you to transition to <a
      className={classes.link} href={getV4Link()} target={'_blank'}
      rel='noreferrer'> Platform V4.3.2 </a> , which offers a range of new capabilities designed to
      improve your experience. Please make the switch at your earliest convenience, as development and support for
      Version 3.6 will be discontinued. Additionally, some pages will be redirected to our new platform in the
      coming week.
    </Alert>
  );
};

DeprecationAlert.propTypes = {};

export default DeprecationAlert;

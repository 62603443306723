import {
    FETCH_ITEMS_BEGIN,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEMS_FAILURE,
    CLEAR_PRODUCTS,
    SET_SEARCH_ACTIVE,
    SET_SEARCH_TERM,
    UPDATE_ARCHIVED_FILTER,
    UPDATE_AVAILABLE_FILTER,
    UPDATE_LOW_STOCK_FILTER,
    UPDATE_PER_PAGE_COUNT,
    UPDATE_PAGE_NUMBER,
    UPDATE_CATEGORY_SELECTION
} from 'actions/spaceActions';

import {applySKUInterface} from 'Interfaces';


const initialState = {
    items: [],
    loading: false,
    uploading: false,
    updating: false,
    error: null,

    itemsSearch: [],
    searchTerm: '',
    searchActive: false,

    itemStocks: [],
    monthlyOutbounds: {},
    orderBySkuError: null,
    itemSku: null,

    fetchedItem: {},

    archivedFilter: {
        yes: false,
        no: true,
        any: false
    },
    onHandFilter: {
        yes: false,
        no: false,
        any: true
    },
    availableFilter: {
        yes: false,
        no: false,
        any: true
    },
    lowStockFilter: false,

    perPage: 25,
    totalCount: 1,
    page: 1,
    totalPages: 1,
    categories: [],
    categoriesFilter: [],
    pagination: {}
}

export default function spaceReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_ITEMS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_ITEMS_SUCCESS:

            if(!state.categoriesFilter.length) {
                let newArray = [...action.payload.categories, {
                    value: '-1',
                    text: 'No Category',
                    isChecked: false
                }];
                return {
                    ...state,
                    loading: false,
                    items: action.payload.products.map(item => ({
                        ...applySKUInterface(item)
                    })),
                    perPage: action.payload.perPage,
                    page: action.payload.page,
                    pagination: action.payload.pagination,
                    totalCount: action.payload.totalCount,
                    totalPages: action.payload.totalPages,
                    categories: action.payload.categories,
                    categoriesFilter: newArray.map(category => {

                        return {
                            id: category.value,
                            title: category.text,
                            isChecked: false
                        }
                    })
                };
            }
            else {
                return {
                    ...state,
                    loading: false,
                    items: action.payload.products.map(item => ({
                        ...applySKUInterface(item)
                    })),
                    perPage: action.payload.perPage,
                    page: action.payload.page,
                    pagination: action.payload.pagination,
                    totalCount: action.payload.totalCount,
                    totalPages: action.payload.totalPages,
                    categories: action.payload.categories
                };
            }


        case FETCH_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };

        case CLEAR_PRODUCTS:
            return {
                ...state,
                items: [],
                perPage: 25,
                page: 1,
                totalCount: 0,
                totalPages: 1,
                categories: [],
                categoriesFilter: []
            }
        case SET_SEARCH_ACTIVE:
            return {
                ...state,
                searchActive: action.active
            }
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.term
            }

        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                page: action.payload.pageNo
            }

        case UPDATE_PER_PAGE_COUNT:
            return {
                ...state,
                perPage: action.payload.count
            }

        case UPDATE_ARCHIVED_FILTER:
            return {
                ...state,
                archivedFilter: {
                    yes: action.property === 'yes' ? action.value : false,
                    any: action.property === 'any' ? action.value : false,
                    no: action.property === 'no' ? action.value : false
                }
            };
        case UPDATE_AVAILABLE_FILTER:
            Object.keys(state[action.property]).forEach(v => (v === action.value ? state[action.property][v] = action.isChecked : state[action.property][v] = false))
            return {
                ...state,
                [action.property]: state[action.property]
            };
        case UPDATE_LOW_STOCK_FILTER:
            return {
                ...state,
                lowStockFilter: action.isChecked
            };

        case UPDATE_CATEGORY_SELECTION:
            let updatedCategoryFilters = state.categoriesFilter.map(category_filter => category_filter.id === action.value ?
                {id: category_filter.id, title: category_filter.title, isChecked: action.isChecked} : category_filter
            )
            return {
                ...state,
                categoriesFilter: updatedCategoryFilters
            };

        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
